.temoignage {
	@extend .text-center;
	padding: 5rem;
	margin: 6rem auto 3rem;
	max-width: 100rem;
	font-weight: 400;
	border: solid .2rem $brand-second;
	position: relative;

	&::after {
		content: url(../images/charte/logo.png);
		font-family: 'fontAwesome';
		position: absolute;
		top: -4rem;
		left: 50%;
		width: 6rem;
		height: 6rem;
		line-height: 6rem;
		margin-left: -3rem;
		text-align: center;
		color: white;
		font-style: normal;
	}
}


.card-blue {
  background-color: rgba(rgb(2, 122, 243), .5);
} 

body {
  background: url(../images/charte/bg-home.jpg);
  background-size: cover;
  background-repeat: no-repeat;
	background-attachment: fixed;
}

.border-orange {
	border: 8px solid $brand-second;
}

.text-shadow {
	text-shadow: .5px .5px .5px white;
	}


.dark-blue {
	color: #1d5fa1;
}


.shadow-orange {
	box-shadow: 15px 15px $brand-second;
}

.shadow-blue {
	box-shadow: 15px 15px  $brand-primary;
}

.card-blue {
  background-color: rgba(rgb(2, 122, 243), .5);
} 

.btn-2 {
	@extend .btn;
	@extend .btn-primary;

	@media(min-width: $screen-md-min) {
		border: none;
		position: relative;
		letter-spacing: 0.25em;
		margin: 0 auto;
		padding: 1rem 2.5rem;
		background: transparent;
		outline: none;
		font-size: 20px;
		color: white;
		z-index:1000;
	
		&::after,
		&::before {
			content: "";
			position: absolute;
			height: 100%;
			width: 55%;
			transform: skewX(30deg);
			transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
			z-index: -2;
		}
		&::before {
			background-color: $brand-primary;
			top: -0.5rem;
			left: 0rem;
		}
		&::after {
			background-color: $brand-second;
			top: 0.5rem;
			left: 8rem;
		}
		&:hover {
			&::before,
			&::after {
				top: 0;
				transform: skewx(0deg);
			}
			&::after {
				left: 0rem;
				width:100%;
	
			}
			&::before {
				left: 5px;
				top: 5px;
	
				width:100%;
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
				
			}
		}
	}
}


.same-size-logo {
  height: 200px;
  width: 200px;
  object-fit: contain;
}

.s6{

	.block1{
		height:50vh;
		background: url(../images/charte/mag1.jpg) no-repeat center;
		background-size: cover;
	border: solid 5px white;
	border-right:solid 3px white;
		// background:red;
	}

	.block2{
		height:50vh;
		background: url(../images/charte/mag2.jpg) no-repeat center;
		background-size: cover;
		border: solid 5px white;
		border-left:solid 3px white;


		// background:green;

	}

}